import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const CancelPage = () => (
  <Layout>
    <SEO
      title="Cancelled"
      keywords={[`australian`, `jewellery`, `exclusive`]}
    />
    />
    <div className="avwrap">
      <div className="avtext">
        <div className="avtitle">Order cancelled</div>
        This order has encountered an error, please refresh the page and try
        again.
        <br />
        <div className="avlink">
          <Link to="/collection">Go back to Collection.</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default CancelPage
